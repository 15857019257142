.bluegradient {
  background: #0c6188;
  background: linear-gradient(#0c6188, #094662); /* Chrome,Safari4+ */
  color: #ffffff;
}

.jumbotron.header {
  background: url("img/bg_image.png") no-repeat center;
  height: 400px;
  margin-bottom: 0;
}

h3.logo {
  margin: 0 0 30px 0;
  text-align: left;
  font-size: 1.7em;
}

header {
  text-align: center;
}

h1.taglineh1 {
  margin-top: 2em;
  font-size: 3.5em;
}

@media (max-width: 1000px) {
  img.kokeile {
    display: none;
  }
}

p.taglinep {
  font-size: 1.5em;
}

.jumbotron.passwordbar {
  background: linear-gradient(
    #094662 0%,
    #0c3944 50%,
    #0c3944 50%,
    #ffffff 51%
  ); /* Chrome10+,Safari5.1+ */
  border-radius: 0%;
}

input.passwordform {
  font-size: 2.5em;
  color: #999999;
}

@media (max-width: 627px) {
  input.passwordform {
    font-size: 1em;
    line-height: 1.3em;
  }
}

.passwordbutton {
  padding-top: 29px;
  padding-bottom: 29px;
}

.jumbotron {
  margin-bottom: 0px;
  padding: 20px;
}

.iconsize {
  width: 100px;
  height: 100px;
  background-color: #f2f2f2;
  margin-bottom: 30px;
  margin-top: 30px;
  line-height: 100px;
  padding: 1em;
}

.passwordfooter {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 60px;
  line-height: 60px;
  background-color: #f2f2f2;
  border-color: #f2f2f2;
}
